import { apiAxiosV1 } from '@/configs/axios';
import { IncentBanner } from '@/model/Banner';

export const BannerService = {
  async getBanners() {
    const url = `/banner/list`;
    const res = await apiAxiosV1.get(url);
    return IncentBanner.fromAll(res?.data);
  },
};
