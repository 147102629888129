import React from 'react';

/**
 * @component
 * @param {Object} props - The component props.
 * @param {String} props.className - The component className.
 * @returns {JSX.Element} A React JSX element representing the component.
 */

export default function Divider({ className = '' }) {
  return (
    <div className={`w-screen -ml-4 h-[1px] text-black/40 ${className}`} />
  );
}
