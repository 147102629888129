import { CONTEST_CARD_VISIBLE } from '@/constants/logs';
import { AndroidService } from '@/service/Android';
import React, { useEffect, useRef, useState } from 'react';

// Function to check if an element is in the viewport
function isElementInViewport(el) {
  if (!el) return false;
  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
function onVisibilityChange(el, callback) {
  var old_visible;
  return function () {
    var visible = isElementInViewport(el);
    if (visible != old_visible) {
      old_visible = visible;
      if (typeof callback == 'function') {
        callback();
      }
    }
  };
}
function LogOnViewPort(props) {
  const ref = useRef(null);
  const [firedOnce, setFiredOnce] = useState(false);
  useEffect(() => {
    if (firedOnce) return; // If firedOnce is true, return early

    const handler = onVisibilityChange(ref.current, () => {
      if (isElementInViewport(ref.current)) {
        AndroidService.logEvent(props.event, props.logPayload);
        setFiredOnce(true); // Set firedOnce to true after firing once
      }
    });
    handler();
    // Attach event listeners
    if (window.addEventListener) {
      window.addEventListener('DOMContentLoaded', handler, false);
      window.addEventListener('load', handler, false);
      window.addEventListener('scroll', handler, false);
      window.addEventListener('resize', handler, false);
    } else if (window.attachEvent) {
      window.attachEvent('onDOMContentLoaded', handler); // Internet Explorer 9+ :(
      window.attachEvent('onload', handler);
      window.attachEvent('onscroll', handler);
      window.attachEvent('onresize', handler);
    }

    return () => {
      // Detach event listeners when component unmounts
      if (window.removeEventListener) {
        window.removeEventListener('DOMContentLoaded', handler);
        window.removeEventListener('load', handler);
        window.removeEventListener('scroll', handler);
        window.removeEventListener('resize', handler);
      } else if (window.detachEvent) {
        window.detachEvent('onDOMContentLoaded', handler);
        window.detachEvent('onload', handler);
        window.detachEvent('onscroll', handler);
        window.detachEvent('onresize', handler);
      }
    };
  }, [firedOnce]);

  return React.cloneElement(props.children, { ref });
}

export default LogOnViewPort;
