'use client';

import { SETTINGTYPES } from '@/constants/Android';
import { AndroidService } from '@/service/Android';
import React from 'react';
import CloseIcon from 'public/close.svg';
import Button from '@/components/ui/Button';
import { StorageService } from '@/service/StorageService';
import { LANGUAGES_CONTENT } from '@/constants/language';

export default function NotificationPermission({
  showRequest,
  setShowRequest,
}) {
  const openSettings = () => {
    AndroidService.openAppSettings(SETTINGTYPES.notifications);
  };

  const handleClose = () => {
    setShowRequest(false);
    StorageService.setNotificationClosed(true);
  };

  if (!showRequest || StorageService.getNotificationClosed()) return null;
  return (
    <div className="p-2 py-4 pt-0 bg-accent-color/10">
      <div className="flex items-center justify-between mb-1">
        <p className="font-semibold text-md">
          {
            LANGUAGES_CONTENT[StorageService.getLanguage()]['home'].notification
              .title
          }
        </p>
        <CloseIcon className="text-xl" onClick={handleClose} />
      </div>
      <div className="flex items-center justify-between gap-8">
        <p className="text-sm text-black/60">
          {
            LANGUAGES_CONTENT[StorageService.getLanguage()]['home'].notification
              .description
          }
        </p>
        <Button onClick={openSettings}>
          {
            LANGUAGES_CONTENT[StorageService.getLanguage()]['home'].notification
              .btn
          }
        </Button>
      </div>
    </div>
  );
}
