'use client';

import AllOffers from '@/app/(Auth)/(home)/components/AllOffers';
import ExternalSDKs from '@/app/(Auth)/(home)/components/ExternalSDKs';
import TopBanner from '@/app/(Auth)/(home)/components/TopBanner';
import { ANDROID_LISTENER_EVENTS, PERMISSIONTYPES } from '@/constants/Android';
import { AndroidService } from '@/service/Android';
import { useEffect, useState } from 'react';
import NotificationPermission from './components/NotificationPermission';
import OfferStories from './components/OfferStories';
import Divider from '@/components/ui/Divider';
import Spacer from '@/components/Spacer';

export default function Page() {
  const [showNotfRequest, setShowNotfRequest] = useState(false);

  const handleNotfInit = () => {
    AndroidService.getPermission(PERMISSIONTYPES.notifications);
    if (!AndroidService.checkPermission(PERMISSIONTYPES.notifications)) {
      setShowNotfRequest(true);
    }
  };

  useEffect(() => {
    handleNotfInit();

    function notificationCheck(event) {
      if (event !== ANDROID_LISTENER_EVENTS.onResume) return;
      if (
        !AndroidService.checkPermission(PERMISSIONTYPES.notifications) &&
        !showNotfRequest
      ) {
        setShowNotfRequest(true);
      } else {
        setShowNotfRequest(false);
      }
    }
    window.notificationCheck = notificationCheck;
    AndroidService.registerLifecycleListener('notificationCheck');

    return () => {
      AndroidService.unregisterLifecycleListener('notificationCheck');
    };
  }, []);

  return (
    <div>
      <NotificationPermission
        showRequest={showNotfRequest}
        setShowRequest={setShowNotfRequest}
      />
      <TopBanner />
      {/* <OfferStories /> */}

      {/* <hr className="h-6" /> */}
      <br />
      <ExternalSDKs />
      <br />
      <AllOffers />
    </div>
  );
}
