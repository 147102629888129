'use client';
import OfferPayout from '@/components/OfferPayout';
import { Offer, OfferV6, Vote } from '@/model/offer';
import {
  convertUnixTimeStamp,
  getOfferType,
  secondsToReadableTime,
} from '@/utils';
import Button from '@/components/ui/Button';
import Link from 'next/link';
import LazyImage from '@/components/LazyImage';
import LogOnViewPort from '@/components/LogOnViewPort';
import {
  OFFER_CARD_CLICK,
  OFFER_CARD_VIEW,
  OFFER_CTA_CLICKED_V2,
} from '@/constants/logs';
import { AndroidService } from '@/service/Android';
import TimeIcon from 'public/assets/icons/time.svg';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';
import UpIcon from 'public/assets/icons/upvote_filled.svg';
import PlayIcon from 'public/assets/icons/play.svg';

/**
 *
 * @param {Object} props
 * @param {OfferV6} props.offer
 * @param {Vote} props.offer.vote
 * @returns
 */
export default function ActiveOfferCard({ offer, index, tagMap }) {
  const ttfp = offer.metrics?.ttfp_ms / 1000;
  const tags = offer.tags
    .filter(d => ['GAMING', 'NEW', 'SINGLE_STEP', 'MULTI_REWARD'].includes(d))
    .filter(d => d !== tagMap?.NEW.id);
  const isNew = !!offer.tags?.find(d => d === tagMap?.NEW.id);
  const tutorialUrl = !!offer.tutorialUrl;

  const userId = StorageService.getUserId()

  return (
    <LogOnViewPort
      logPayload={{
        offer_id: offer.offerId,
        position: index,
        userId: userId
      }}
      event={OFFER_CARD_VIEW}
    >
      <div className="flex flex-col gap-1 rounded-lg bg-white border relative shadow-md">
        <Link
          href={`/offer/${offer?.offerId}`}
          className={`grid grid-cols-[60px_minmax(0,1fr)_minmax(0,auto)] gap-2 items-center p-2 `}
          scroll={false}
        >
          <LazyImage
            src={offer?.iconImage}
            alt={offer?.title}
            className="w-[60px] h-[60px] rounded-lg"
          />
          <div className="flex flex-col justify-between">
            {/* <Spacer size="small" /> */}
            <div>
              <h1 className="flex-1 text-sm font-semibold line-clamp-2 text-ellipsis max-w-[250px]">
                {offer?.title}
              </h1>
              <p className="text-xs text-black/40 w-[200px] line-clamp-1 text-ellipsis">
                {getOfferType(offer.offerType)}
              </p>
            </div>
            {/* <Spacer size="small" /> */}
          </div>
          <Button
            variant="solid"
            className="self-center w-24 px-3 py-0 mt-auto font-bold"
          >
            <OfferPayout size="medium" amount={offer?.payout?.amount} />
          </Button>
        </Link>
        {(!!ttfp || !!tags?.length || offer.metrics.upvotes > 0) && (
          <div className="flex items-center justify-between gap-4     p-2">
            <div className="flex flex-wrap gap-2">
              {ttfp ? (
                <div className="flex items-center gap-1 text-accent-color">
                  <TimeIcon className="text-xs" />
                  <p className="text-xs">{secondsToReadableTime(ttfp)}</p>
                  {!!tags?.length && (
                    <span className="w-1 h-1 ml-2 rounded-full" />
                  )}
                </div>
              ) : null}
              {tags?.length ? (
                <>
                  {tags.map((d, i) => (
                    <div
                      className="flex items-center gap-2 bg-accent-color/10 rounded-lg py-1 px-2"
                      key={i}
                    >
                      <p className="text-xs text-accent-color font-semibold">
                        {tagMap[d].display}
                      </p>
                    </div>
                  ))}
                </>
              ) : null}
            </div>
            {offer.metrics.upvotes > 0 && (
              <div className="flex items-center gap-1 ml-auto">
                <p className="mt-1 text-sm text-black">
                  {offer.metrics.upvotes}
                </p>
                <UpIcon className={'mb-1'} />
              </div>
            )}
          </div>
        )}
        {tutorialUrl ? (
          <div className="absolute right-0 top-0 flex items-center justify-center bg-[#5F328D] h-fit w-fit p-1 rounded-tr-lg rounded-bl-lg">
            <p className="text-xs font-medium text-white">
              {
                LANGUAGES_CONTENT[StorageService.getLanguage()].home.tutorial
                  .title
              }
            </p>{' '}
            <PlayIcon className="text-white text-[10px] ml-1" />
          </div>
        ) : isNew ? (
          <div className="absolute right-0 top-0 flex items-center justify-center bg-[#DBA32C] h-fit w-fit p-1 rounded-tr-lg rounded-bl-lg">
            <p className="text-xs font-medium text-black">
              {LANGUAGES_CONTENT[StorageService.getLanguage()].home.tags.NEW}
            </p>
          </div>
        ) : null}
      </div>
    </LogOnViewPort>
  );
}
