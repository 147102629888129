'use client';

import { useMemo } from 'react';

import usePaginatedService from '@/hooks/usePaginatedService';
import { OfferService } from '@/service/Offer';
import Link from 'next/link';
import Skeleton from 'react-loading-skeleton';
import { AndroidService } from '@/service/Android';
import Spacer from '@/components/Spacer';
import Divider from '@/components/ui/Divider';

export default function OfferStories() {
  const {
    data: activeOffers,
    isLoaded,
    isLoading,
    error,
  } = usePaginatedService(OfferService.getOngoingOffers, {
    makeFirstCall: true,
    count: 10,
  });

  const nonPriorityOffers = useMemo(() => activeOffers, [activeOffers]);

  if (!isLoading && !!error) return null;

  if (!isLoaded || (isLoading && !activeOffers)) {
    return (
      <>
        <div className="max-h-[100px] p-4">
          <div className="flex gap-2 items-center justify-between">
            <Skeleton width={100} height={14} /> {/* Skeleton for heading */}
            <Skeleton width={50} height={14} />{' '}
          </div>
          <div className="flex gap-2 max-w-screen-sm overflow-hidden">
            {Array.from({ length: 6 }).map((_, index) => (
              <div key={index} className="h-[60px] w-[60px] rounded-[50%]">
                <Skeleton width={55} height={55} circle />
              </div>
            ))}
          </div>
        </div>
        <Spacer />
        <Divider />
        <Spacer />
      </>
    );
  }
  if (!activeOffers?.length) {
    AndroidService.logEvent('offer_404', null);
    return null;
  }

  return (
    <>
      <div className="max-h-[100px]">
        <div className="flex items-center justify-between">
          <p className="text-lg mb-2">Continue offers</p>
          <Link
            href={'/profile'}
            className="font-semibold text-sm underline underline-offset-2 text-white/60"
          >
            View All
          </Link>
        </div>
        <div className="flex gap-2 max-w-[calc(100vw_-_2.2rem)] md:max-w-[calc(640px_-_2.2rem)] overflow-auto">
          {nonPriorityOffers.map((offer, index) => {
            const radius = 26;
            const random = Math.random() * 100;
            const progress = random < 30 ? 60 : random;
            const circumference = 2 * Math.PI * radius;
            const offset = circumference - (progress / 100) * circumference;

            return (
              <Link
                href={`/offer/${offer.offerId}`}
                key={index}
                className=" h-[60px] w-[60px] rounded-[50%] object-cover  "
              >
                <div className="relative">
                  <svg
                    className="size-full"
                    width="60"
                    height="60"
                    viewBox="0 0 60 60"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="30"
                      cy="30"
                      r="26"
                      fill="none"
                      className="stroke-current text-gray-200 dark:text-neutral-700"
                      strokeWidth="6"
                    ></circle>
                    <g className="origin-center -rotate-90 transform">
                      <circle
                        cx="30"
                        cy="30"
                        r="26"
                        fill="none"
                        className="stroke-current text-blue-600 dark:text-blue-500"
                        strokeWidth="6"
                        strokeDasharray={circumference}
                        strokeDashoffset={offset}
                      ></circle>
                    </g>
                    <foreignObject x="5" y="5" width="50" height="50">
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img
                        alt={offer.name}
                        src={offer.iconImage}
                        className="h-[50px] w-[50px] rounded-[50%] object-cover"
                      />
                    </foreignObject>
                    <foreignObject x="5" y="5" width="50" height="50">
                      <div className="h-[50px] w-[50px] rounded-[50%] object-cover text-black/40/70 flex items-center justify-center">
                        <p className="text-xl text-white font-bold">
                          {index + 1}/5
                        </p>
                      </div>
                    </foreignObject>
                  </svg>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <Spacer />
      <Divider />
      <Spacer />
    </>
  );
}
